<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="AppicReport"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <AppicReportListingV2></AppicReportListingV2>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
// import ExportTable from "Components/Appic/ExportTable";
import AppicReportListingV2 from "Components/Appic/AppicReportListingV2";
import {mapFields} from "vuex-map-fields";

export default {
    name: "AppicReport",
    components: { AppicReportListingV2 },
    title: '',
    data() {
        return {
            loader: false
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        newContract(){
            let tab = window.open('/v1/contracts/add','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    // mounted(){
    //     this.$title = this.$t('message.titles.appicReport')
    // }
}
</script>

<style>
    .v-data-table v-data-table__wrapper table tbody td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>